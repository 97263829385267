<template>
  <div>
    <Navbar />

    <section class="px-6 py-10 mx-auto flex-1 container">
      <div class="container mx-auto flex items-center justify-between mb-5">
        <h1 class="text-2xl 2xl:text-4xl font-semibold">Cerca la tua serata</h1>

        <div class="flex items-center gap-5">
          <router-link :to="{ name: 'Sessions' }" class="btn btn--red"
            >&larr; Torna alle serate</router-link
          >
        </div>
      </div>

      <form class="flex flex-col items-start">
        <div class="flex gap-2 items-center">
          <label for="first-name" class="">Codice serata:</label>
          <input
            v-model="code"
            type="text"
            name="code"
            id="code"
            autocomplete="given-name"
            class="border-none rounded shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
          />
        </div>

        <button v-on:click.prevent="findByCode" class="btn btn--red">
          Cerca
        </button>
      </form>

      <div class="mt-10" v-if="sessions.length && code.length">
        <div v-for="session in sessions" :key="session.id">
          <CardSession
            :id="session.id"
            :name="session.name"
            :date="session.date"
            :status="session.status"
            :code="session.code"
            :parent="$route.name"
          />
        </div>
      </div>

      <div
        v-if="error"
        class="mt-2 p-1 border rounded-sm text-xs bg-red-100 border-red-200 text-red-500"
      >
        Nessuna serata trovata con questo id.
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import { getClient } from "@/includes/api";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import CardSession from "@/components/CardSession.vue";

export default {
  props: ["id", "name"],
  components: { Footer, Navbar, CardSession },
  data() {
    return {
      code: null,
      sessions: [],
      error: null,
    };
  },
  methods: {
    async findByCode() {
      try {
        let code = this.code;
        console.log(code);
        let apiClient = getClient();
        console.log("Getting sessions...");
        let session = await apiClient.SessionFindByCode(code);
        if (session !== null) {
          this.sessions.push(session);
          this.error = null;
        }
      } catch (err) {
        console.error(err);
        this.error = "Nessuna serata presente con il codice che hai inserito";
      }
    },
  },
};
</script>
